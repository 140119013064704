import Api from '@/services/Api'

export default {
  findAll (pagination, idEmpresaCliente) {
    const param = {
      idEmpresaCliente, pagination
    }
    return Api().post('fornecedores', param)
  },
  insert (fornecedor) {
    return Api().post('fornecedor', fornecedor)
  },
  update (fornecedor) {
    return Api().put('fornecedor', fornecedor)
  },
  delete (fornecedor) {
    return Api().delete(`fornecedor?id=${fornecedor.id}`, fornecedor)
  },
  findByParam (param, pagination) {
    param = {
      param, pagination
    }
    return Api().post('fornecedoresParam', param)
  },
  findById (fornecedor) {
    const url = `fornecedor/${fornecedor.id}`
    return Api().get(url)
  },
  buscaFornecedoresPorParam (param, idEmpresaCliente) {
    return Api().get(`fornecedoresPorParam?idEmpresaCliente=${idEmpresaCliente}&param=${param}`)
  }

}
